<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
      <img
        src="../../assets/images/logo-white.png"
        alt="CanPay Logo"
        class="brand-image"
        style="opacity: 0.8; margin-left: 0"
      />
      <span class="brand-text font-weight-light">Merchant Admin</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar scroll-style-3">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <b-avatar
            variant="success"
            :text="name_initials"
            class="mr-3"
          ></b-avatar>
        </div>
        <div class="info">
          <a
            href="#collapseOne"
            class="d-block accordion-toggle collapsed"
            data-toggle="collapse"
          >
            {{ full_name }} &nbsp;
            <i class="fa fa-caret-down"></i>
          </a>
          <div id="collapseOne" class="collapse">
            <ul
              class="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li class="nav-item" style="margin-left: -17px; margin-top: 10px">
                <router-link to="/userProfile" class="nav-link">
                  <i class="nav-icon fas fa-edit"></i>
                  <p>Profile</p>
                </router-link>
              </li>
              <li
                v-if="user.role_name === constants.role_regional_manager"
                class="nav-item"
                style="margin-left: -17px; margin-top: 10px"
              >
                <router-link to="/bankDetails" class="nav-link">
                  <i class="nav-icon fas fa-university"></i>
                  <p>Bank Details</p>
                </router-link>
              </li>
              <li class="nav-item" style="margin-left: -17px; margin-top: 10px">
                <router-link to="/changePassword" class="nav-link">
                  <i class="nav-icon fas fa-unlock-alt"></i>
                  <p>Change Password</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                style="margin-left: -17px; margin-top: 10px; cursor: pointer"
              >
                <a class="nav-link" @click="logout">
                  <i class="nav-icon fas fa-power-off"></i>
                  <p>Logout</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
          v-if="is_sposor_store"
        >
          <li class="nav-item">
            <router-link to="/sponsordashboard" class="nav-link">
              <i class="nav-icon fas fa-columns"></i>
              <p>Sponsor Dashboard</p>
            </router-link>
          </li>
        </ul>
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
          v-else
        >
          <!-- Add icons to the links using the .nav-icon class
          with font-awesome or any other icon font library-->
          <li class="nav-item" v-if="user.role_name !== constants.role_new_remotepay_manager">
            <router-link to="/dashboard" class="nav-link">
              <i class="nav-icon fas fa-columns"></i>
              <p>Dashboard</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager">
            <router-link to="/ecommercedashboard" class="nav-link">
              <i class="nav-icon fas fa-columns"></i>
              <p>RemotePay Management</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="user.role_name !== constants.role_new_remotepay_manager">
            <router-link to="/releases" class="nav-link">
              <i class="nav-icon fas fa-hashtag"></i>
              <p>Releases</p>
            </router-link>
          </li>
          <li class="nav-item has-treeview"  v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager && user.role_name !== constants.role_new_remotepay_manager">
            <a href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>
                Users
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/regionalManager" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Regional Managers</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/remotepayManager" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Remotepay Manager</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/storeManagers" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Store Managers</p>
                </router-link>
              </li>
                <li class="nav-item" >
                <router-link to="/accountant" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Accountant</p>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/deviceManagers" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Device Manager</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/employees" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Employees</p>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item"  v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager  && user.role_name !== constants.role_new_regional_manager && user.role_name !== constants.role_new_remotepay_manager">
            <router-link to="/stores" class="nav-link">
              <i class="nav-icon fas fa-building"></i>
              <p>Stores</p>
            </router-link>
          </li>
          <li class="nav-item"  v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager && user.role_name !== constants.role_new_remotepay_manager">
            <router-link to="/emailaddressDailyTransaction" class="nav-link">
              <i class="nav-icon fas fa-at"></i>
              <p>Email addresses for Daily</p> <br/>
              <p>Transaction Activity</p>
            </router-link>
          </li>
          <li class="nav-item"  v-if="show_void_transaction_menu && user.role_name !== constants.role_accountant && user.role_name !== constants.role_new_remotepay_manager">
            <router-link to="/voidtransactions" class="nav-link">
              <i class="nav-icon fa fa-ban"></i>
              <p>Void Transactions</p>
            </router-link>
          </li>

          <li class="nav-item has-treeview" v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager && user.role_name !== constants.role_new_remotepay_manager">
            <a href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fa fa-history"></i>
              <p>
                POS Transaction History
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  to="/transactions"
                  class="nav-link"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <span>POS</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item has-treeview" v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager">
            <a href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fas fa-table"></i>
              <p>
                RemotePay
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item"  v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager  && user.role_name !== constants.role_new_regional_manager && user.role_name !== constants.role_new_remotepay_manager">
                <router-link to="/storeapikeys" class="nav-link">
                  <i class="nav-icon fas fa-key"></i>
                  <p>Store API Keys</p>
                </router-link>
              </li>
              <li class="nav-item"  v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager">
                <router-link to="/remotepaytransactionshistory" class="nav-link">
                  <i class="nav-icon fas fa-history"></i>
                  <p>RemotePay</p> <br/>
                  <p>Transaction History</p>
                </router-link>
              </li>
              <li class="nav-item has-treeview"  v-if="user.role_name !== constants.role_new_remotepay_manager">
              <a href="javascript:void(0);" class="nav-link">
                <i class="nav-icon fas fa-table"></i>
                <p>RemotePay <i class="right fas fa-angle-left"></i></p> <br/>
                <p>Holiday List and Timings</p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item">
                  <router-link
                    to="/storeTimingHoliday"
                    class="nav-link"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <span>Store Timing & Holidays</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/holidayTemplateMaster"
                    class="nav-link"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <span>Template Master</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/holidayMaster"
                    class="nav-link"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <span>Holiday Master</span>
                  </router-link>
                </li>
              </ul>
              </li>
            </ul>
          </li>

          <li class="nav-item has-treeview" v-if="user.role_name !== constants.role_new_remotepay_manager">
            <a href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fas fa-table"></i>
              <p>
                Reports
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  to="/merchantLocationTransactions"
                  class="nav-link"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Transaction Report</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/settlementReport" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Settlement/Fees Report</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/historicalSettlementReport"
                  class="nav-link"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Historical Settlement/Fees Report</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/tipReportv1" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Tip Report V1 (Shifts)</span>
                </router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link to="/tipReportv2" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Tip Report V2 (Employee)</span>
                </router-link>
              </li> -->
            </ul>
          </li>
        </ul>
        
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>
<script>
import * as AdminLte from "../../../public/js/adminlte.js";
import Constants from "@/common/constant.js";
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("store_user")),
      full_name: null,
      name_initials: null,
      constants: Constants,
      show_void_transaction_menu:localStorage.getItem('disable_store_count') > 0 ? true : false,
      is_sposor_store:localStorage.getItem('sposor_store_count') > 0 ? true : false,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("store_user");
      localStorage.clear();
      success("Logged out successfully.");
      this.$router.push("/login");
    },
  },
  mounted() {
    var self = this;
    if(self.user.role_name == Constants.role_corporate_parent){
      self.full_name = self.user.contact_person_first_name + " " + self.user.contact_person_last_name;
    }else{
      if (self.user.middle_name) {
        self.full_name =
        self.user.first_name +
        " " +
        self.user.middle_name +
        " " +
        self.user.last_name;
      } else {
        self.full_name = self.user.first_name + " " + self.user.last_name;
      }
    }
    
    let matches = self.full_name.match(/\b(\w)/g);
    self.name_initials = matches.join("");
    $('[data-widget="treeview"]').each(function () {
      AdminLte.Treeview._jQueryInterface.call($(this), "init");
    });

    console.log("disable count"+self.show_void_transaction_menu);
  },
};
</script>
<style>
.nav-sidebar .menu-open>.nav-treeview {
    display: inline-block !important;
    width: -webkit-fill-available !important;
}
</style>
